import { INITIAL_ANALYSIS_RESPONSE_TERA } from "../constants";
import { Coordinate, MapTarget, MapBlock } from "../redux/@types/forms";


export const AnalysisResponseTERA = (analysisResponse?: any) => {
    let response = analysisResponse && JSON.parse(JSON.stringify(analysisResponse))
        || JSON.parse(JSON.stringify(INITIAL_ANALYSIS_RESPONSE_TERA));

    const getData = (dataKey: string) => {
        if (response[dataKey]) {
            console.log(dataKey, response[dataKey]);
            return response[dataKey].answer[0];
        }
        return null;
    }

    const setData = (dataKey :string, val: any, format?: string, questionText?: string) => {
        let answer;
        switch (format) {
            case 'map_answer':
                answer = [["lat", val.lat], ["lng", val.lng]];
                break;
            default:
                answer = [val];
                break;
        }
        let d = {
            key: dataKey,
            answer: answer,
            comment: false,
            question_text: questionText,
            format
        };
        // console.log("SETTING DATA", d);
        return response[dataKey] = d;
    }


    const get = () => { return response }

    return {
        get,
        getData,
        setData,
    };
}