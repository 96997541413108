import { Analysis, Classification } from "../redux/@types/forms";
import { AnalysisResponse } from "./AnalysisResponse";
import { AnalysisResponseTERA } from "./AnalysisResponseTERA";
const FormulaParser = require("hot-formula-parser").Parser;

const parser = new FormulaParser();


export const parseFormula = (formula: string, majorType: string, analysis: Analysis) => {
    // console.log("CLASSIFICATION", classification);
    // console.log("ANALYSIS", analysis);
    let analysisResponse: any;
    // console.log("MAJOR TYPE", majorType);
    switch(majorType) {
        case 'AHVVA':
            analysisResponse = AnalysisResponse(analysis?.response);
            break;
        case 'TERA':
            analysisResponse = AnalysisResponseTERA(analysis?.response);
            break;
        default:
            analysisResponse = AnalysisResponse(analysis?.response);
            break;
    }
    // console.log("ANALYSISRESPONSE GET",analysisResponse.get());
    // console.log("SURVEY",classification.surveyData);
    const re = /\$\{\w*\}/g;
    // const matched = re.exec(formula) || "";
    // if (matched && matched.length > 0) {
        // console.log("MATCHED", matched, matched.length);
        const formulaAdjusted = formula.replace(re, (match) => {
            const dataKey = match.replace("${", "").replace("}", "");
            // console.log("NEW DATA KEY", dataKey);
            const keyAnswer = analysisResponse.getData(dataKey);
            // console.log("NEW KEY ANSWER", keyAnswer);
            if (typeof keyAnswer == "object") {
                const stringifiedAnswer = `'${JSON.stringify(keyAnswer)}'`;
                // console.log("STRINGIFIED ANSWER", stringifiedAnswer);
                // formulaAdjusted = formulaAdjusted.replace(match, stringifiedAnswer);
                return stringifiedAnswer;
            }
            return match;
            // return `${match} ${$1}`
        })
        // console.log("FORMULA ADJUSTED",formulaAdjusted);
        const result = parser.parse(formulaAdjusted).result;
        // console.log("RESULT", result, result !== '', JSON.parse(result));
        try {
            if (result !== '') return JSON.parse(result);
        } catch (e) {
            return result;
        }

        // const dataKey = matched[0].replace("${", "").replace("}", "");
        // console.log("DATA KEY", dataKey);
        // const keyAnswer = analysisResponse.getData(dataKey);
        // console.log("KEY ANSWER", keyAnswer);
        // console.log("P2", dataKey, keyAnswer);
        // if (typeof keyAnswer == "object") {
            // const stringifiedAnswer = `'${JSON.stringify(keyAnswer)}'`;
            // const newstring = formula.replace(matched[0], stringifiedAnswer);
            // console.log("STRINGIFIED KEY ANSWER", stringifiedAnswer);
            // console.log("COMPONENT AUTO", component.auto);
            // console.log("NEW STRING", newstring);
            // console.log("PARSER LOG NEW STRING", parser.parse(newstring));
            // const result = parser.parse(newstring).result;
            // console.log("RESULT",result);
            // console.log("P3", stringifiedAnswer, formula, newstring, parser.parse(newstring), result);
            // if (result !== '') {
                // console.log("P4", JSON.parse(result));
                // console.log("RESULT PARSED", JSON.parse(result));
                // const currentKey = component.key;
                // const format = 'single_answer';
                // return JSON.parse(result);
                // const questionData = component.views.question;
                // const questionText = questionData.view.data.default;
                // analysisResponse.setData(currentKey, parsedResult, format, questionText);
            // } 
        // }
    // }
}